import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './modal.scss'

interface Props {
    isShown: boolean;
    canValidate?: boolean;
    onClose?: any;
    onValid?: any;
    modalContent?: ReactNode;
    headerText?: string;
    children?: ReactNode;
}

interface State {
    isButtonEnabled: boolean;
}

class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isButtonEnabled: !!props.canValidate,  // Initialize with the current prop value
        };
        this.onClose = this.onClose.bind(this);
        this.onValid = this.onValid.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (nextProps.canValidate !== prevState.isButtonEnabled) {
            return {
                isButtonEnabled: !!nextProps.canValidate,
            };
        }
        return null;
    }

    onClose = () => {
        if (this.props.onClose) this.props.onClose();
    }

    onValid = () => {
        if (this.props.onValid) {
            this.props.onValid();
            this.onClose();
        }
    }

    render() {
        return this.props.isShown
            ? (ReactDOM.createPortal(
                  <React.Fragment>
                      <div className="backdrop" />
                      <div className="wrapper">
                          <div className="styledModal">
                              <div className="headerModal">
                                  <div className="headerText">{this.props.headerText}</div>
                              </div>
                              <div className="contentModal">{this.props.modalContent}</div>
                              <div className="footerModal">
                                  <button
                                      className="customButton valid-btn right margin5"
                                      disabled={!this.state.isButtonEnabled}
                                      onClick={this.onValid}
                                  >
                                      Valider
                                  </button>
                                  <button className="customButton cancel-btn right margin5" onClick={this.onClose}>
                                      Annuler
                                  </button>
                              </div>
                          </div>
                      </div>
                  </React.Fragment>,
                  document.body
              ) as ReactNode)
            : null;
    }
}

export default Modal;
