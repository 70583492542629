import { AccountUser } from "../AccountUser/user.model";

export class Account {
    public id!: string;
    public name!: string;
    public description!: string;
    public userList!: AccountUser[];

    constructor(init?: Partial<Account>) {
        Object.assign(this, init);
    }
}