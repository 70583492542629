import React, { Component } from "react";
import { Redirect, Route } from "react-router";
import UserAdminInterface from "./pages/user-admin-interface/User-Admin-Interface";
import userAdminService from "./services/user-admin.service";

export interface Props {
    path: string;
    component: React.FC;
    exact: boolean
  }
  
  interface State{
      authentified : boolean
  }


class AuthRoute extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { authentified : false};
    }

    componentDidMount() {
        userAdminService.protected().then(response => {
            if(response.status == 200) {
                this.setState({authentified: true})
            }
            else {
                this.setState({authentified: false})
            }
            })
            .catch(error => {
                this.setState({authentified: false})
        })
    }

    render(){
        userAdminService.protected().then(response => {
            if(response.status == 200) {
                this.setState({authentified: true})
            }
            else {
                this.setState({authentified: false})
            }
            })
            .catch(error => {
                this.setState({authentified: false})
            })

        if(this.state.authentified)
            return (<Route path="/user-administration" exact={true} component={UserAdminInterface}/>)
        else
            return  (<Redirect  to="/"  />)
    }
}

export default AuthRoute;
