import React, { useEffect, useState } from 'react';
import './Clients-List.scss';
import { Account } from '../../data/models/account/account.model';
import UserAdminService from '../../services/user-admin.service';
import Loading from '../components/custom-components/loading/loading';
import { PoiService } from '../../services/poi.service';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import NotificationSnackbar from '../components/custom-components/notificationSnackbar/NotificationSnackbar';

interface ClientData extends Account {
    poiCount: number;
    activePoiCount: number;
    inactivePoiCount: number;
}

const ClientsList: React.FC = () => {
    const [clients, setClients] = useState<ClientData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);

    useEffect(() => {
        const fetchClientsData = async () => {
            try {
                setLoading(true);

                const clientResponse = await UserAdminService.getAllClient();
                const accounts: Account[] = clientResponse.data.payload.data.map((acc: any) => new Account(acc));

                const poiStats = await PoiService.getAllPoiStats();

                const clientsData = accounts.map((account) => {
                    const stats = poiStats.find((stat) => stat.clientId === account.id);
                    return {
                        ...account,
                        poiCount: stats ? stats.totalPoi : 0,
                        activePoiCount: stats ? stats.activePoi : 0,
                        inactivePoiCount: stats ? stats.inactivePoi : 0,
                    };
                }).filter((client) => client.poiCount > 0);

                setClients(clientsData);
            } catch (error) {
                setSnackbarMessage('Erreur lors de la récupération des clients :'+error);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };

        fetchClientsData();
    }, []);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const exportToCSV = () => {
        const headers = ['Nom', 'Description', 'Poi', 'Poi actif', 'Poi inactif'];
        const csvRows = [
            headers.join(';'),
            ...clients.map((client) =>
                [
                    `"${client.name}"`,
                    `"${client.description || 'N/A'}"`,
                    client.poiCount,
                    client.activePoiCount,
                    client.inactivePoiCount,
                ].join(';')
            ),
        ];

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients_export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const totalPages = Math.ceil(clients.length / rowsPerPage);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="clients-list">
            <p className="total-clients">Nombre total de clients : {clients.length}</p>
            <div className="table-container">
                <table className="clients-table">
                    <thead>
                        <tr>
                            <th className="align-left">Nom</th>
                            <th className="align-right">Poi</th>
                            <th className="align-right">Poi actif</th>
                            <th className="align-right">Poi inactif</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
                            <tr key={client.id}>
                                <td className="align-left">{client.description || 'N/A'}</td>
                                <td className="align-right">{client.poiCount}</td>
                                <td className="align-right">{client.activePoiCount}</td>
                                <td className="align-right">{client.inactivePoiCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <div className="rows-per-page">
                    <label htmlFor="rows-per-page">Lignes par page :</label>
                    <select id="rows-per-page" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        {[5, 10, 25, 50, 100].map((rows) => (
                            <option key={rows} value={rows}>{rows}</option>
                        ))}
                    </select>
                </div>
                <div className="page-controls">
                    <button onClick={() => handleChangePage(0)} disabled={page === 0} title="Première">
                        <FaAngleDoubleLeft />
                    </button>
                    <button onClick={() => handleChangePage(page - 1)} disabled={page === 0} title="Précédente">
                        <FaAngleLeft />
                    </button>
                    <span>
                        Page {page + 1} sur {totalPages}
                    </span>
                    <button onClick={() => handleChangePage(page + 1)} disabled={page >= totalPages - 1} title="Suivante">
                        <FaAngleRight />
                    </button>
                    <button onClick={() => handleChangePage(totalPages - 1)} disabled={page >= totalPages - 1} title="Dernière">
                        <FaAngleDoubleRight />
                    </button>
                </div>
            </div>
            <button className="export-button" onClick={exportToCSV}>Exporter en CSV</button>
            <NotificationSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default ClientsList;
