import UserAdminService from './user-admin.service'
class TokenService {

    tokenKey = 'user';
    rememberKey = '';

    getUser() : string {
        let user = localStorage.getItem(this.tokenKey)
        let userInfo;
        if(user)
        {
            userInfo = JSON.parse(user)
        }
        return userInfo?.access_token;
    }

    setUser(token: string) {
        localStorage.setItem(this.tokenKey, token);
    }

    removeUser() {
        localStorage.removeItem(this.tokenKey);
    }

    refreshUser() {
        let remember = this.getRemember();
        if(remember) {
            let token = this.getUser();
            UserAdminService.refreshToken()
            .then((response) => {
                if(response.data.access_token) {
                    this.setUser(response.data.access_token);
                }
            });
        }
    }

    getAuthHeader() {
        let accessToken = this.getUser()
        if(accessToken) {
            return {'Authorization': 'Bearer ' + accessToken}
        }
    }

    getRemember(){
        let userInfo = localStorage.getItem(this.rememberKey)
        let remember
        if(userInfo) {
            remember = JSON.parse(userInfo);
        }
        return remember;
    }

    setRemember(rememberMe: string){
        localStorage.setItem(this.rememberKey, rememberMe);
    }
}
  
  export default new TokenService();