import { AxiosResponse } from 'axios';
import http from '../../http-common';
import TokenService from './token.service';

class ConsoleAdminService {
    public getModuleAccess(client_id: string): Promise<AxiosResponse<any>> {
        return http.get(`/api/client/${client_id}/getModuleAccess`, { headers: TokenService.getAuthHeader() });
    }
    public updateModuleAccess(client_id: string, data: JSON): Promise<AxiosResponse<any>> {
        let formData = {"props": data}
        return http.post(`/api/client/${client_id}/updateModuleAccess`, formData, { headers: TokenService.getAuthHeader() });
    }
    public getClientOptions(client_id: string): Promise<AxiosResponse<any>> {
        return http.get(`/api/client/${client_id}/getClientOptions`, { headers: TokenService.getAuthHeader() });
    }
    public updateClientOptions(client_id: string, data: JSON): Promise<AxiosResponse<any>> {
        let formData = {data}
        return http.post(`/api/client/${client_id}/updateClientOptions`, formData.data, { headers: TokenService.getAuthHeader() });
    }
}

export default new ConsoleAdminService();