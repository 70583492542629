export enum RoleLevel {
    GLOBAL = "Global",
    LOCAL = "Local"
}

export enum RoleDetail {
    ADMIN = "Admin",
    GMB = "Gmb",
    CAMPAIGN = "Campaign",
    FRANCHISE = "Franchise"
}

export enum UserStatus {
    UNCONFIRMED = "UNCONFIRMED",
    CONFIRMED = "CONFIRMED",
    ARCHIVED = "ARCHIVED",
    COMPROMISED = "COMPROMISED",
    RESET_REQUIRED = "RESET_REQUIRED",
    FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
    UNKNOWN = "UNKNOWN"
}