import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';

interface LangListProps {
  languages: { value: string; countries: string[] }[];
  selectedLanguage: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const LangList: React.FC<LangListProps> = ({ languages, selectedLanguage, handleChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="language-select-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        value={selectedLanguage}
        onChange={handleChange}
        displayEmpty
      >
        {languages.map((lang) => (
          <MenuItem key={lang.value} value={lang.value}>
            {lang.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LangList;
