import React from 'react';
import './Home-Page.scss';
import { Account } from '../../data/models/account/account.model';
import Header from '../components/header/Header';
import { useLocation } from 'react-router-dom';

interface HomePageProps {
  accountList: Account[]
}

interface LocationState {
  clientId?: string;
  value?: number;
}

const HomePage: React.FC<HomePageProps> = ({
  accountList = [],

}) => {
  const location = useLocation<LocationState>();
  const { clientId = '', value = 0 } = location.state || {};
  return (
    <div className="background-admin">
      <div className="frame">
      <Header
            clientId = {clientId}
            currentAccountList={accountList}
            value = {value}
          />

      </div>
    </div>
  );
};

export default HomePage;
