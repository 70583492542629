import React from 'react';
import Login from './../login/Login'
import './Home.scss'
import evermaps_logo_white from '../../assets/img/logo_evermaps_white.png'


export interface Props { }
interface State { }
class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="background-home">
        <br></br>
        <br></br>
        <Login />
        <img src={evermaps_logo_white} className="logo bottom-right w10pc"></img>
      </div>
    );
  }

}

export default Home;