import TokenService from './token.service';
import http from '../../http-common';

export interface PoiStats {
    clientId: string;
    schemaName: string;
    totalPoi: number;
    activePoi: number;
    inactivePoi: number;
}

export class PoiService {
    public static async getAllPoiStats(): Promise<PoiStats[]> {
        try {
            const response = await http.get('/api/client/poiStats', {
                headers: TokenService.getAuthHeader(),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
