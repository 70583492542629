import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Button, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CountryForm from './CountryForm';
import './CountryList.scss';

interface Country {
    name: { [key: string]: string };
    iso_alpha_2: string;
    iso_alpha_3: string;
    em_reg_id: string;
    em_geocode_id: string;
}

interface CountryListProps {
    countries: {
        name: { [key: string]: string };
        iso_alpha_2: string;
        iso_alpha_3: string;
        em_reg_id: string;
        em_geocode_id: string;
    }[];
    selectedLanguage: string;
    selectedCountry: string;
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handleDeleteCountry: (iso_alpha_2: string) => void;
    handleAddCountry: (countryData: any) => void;
    handleUpdateCountry: (iso_alpha_2: string, updatedCountry: any) => void;
}

const CountryList: React.FC<CountryListProps> = ({
    countries,
    selectedLanguage,
    selectedCountry,
    handleChange,
    handleDeleteCountry,
    handleAddCountry,
    handleUpdateCountry
}) => {
    const [openForm, setOpenForm] = useState(false);
    const [editingCountry, setEditingCountry] = useState<Country | undefined>(undefined);;

    const openAddCountryForm = () => {
        setEditingCountry(undefined);
        setOpenForm(true);
    };

    const openEditCountryForm = (country: any) => {
        setEditingCountry(country);
        setOpenForm(true);
    };

    const handleSaveCountry = (countryData: any) => {
        if (editingCountry) {
            handleUpdateCountry(editingCountry?.iso_alpha_2, countryData);
        } else {
            handleAddCountry(countryData);
        }
        setOpenForm(false);
    };

    return (
        <div className="country-list">
            <FormControl className="form-control" fullWidth>
                <InputLabel className="select-label" id="country-select-label">Country</InputLabel>
                <Select
                    labelId="country-select-label"
                    value={selectedCountry}
                    onChange={handleChange}
                    MenuProps={{ classes: { paper: "menu-paper" } }}
                >
                    {countries.map((country, index) => (
                        <MenuItem key={index} value={country.iso_alpha_2} className="menu-item">
                            <ListItemText primary={country.name[selectedLanguage] || `Unknown (${country.iso_alpha_2})`} />
                            <ListItemIcon>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    openEditCountryForm(country);
                                }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteCountry(country.iso_alpha_2);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemIcon>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="button-container">
                <Button startIcon={<AddIcon />} onClick={openAddCountryForm} color="primary">
                    Add Country
                </Button>
            </div>
            <CountryForm
                open={openForm}
                onClose={() => setOpenForm(false)}
                onSave={handleSaveCountry}
                initialData={editingCountry}
            />
        </div>
    );
};

export default CountryList;
