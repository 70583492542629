import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, TextField, IconButton, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

interface CountryFormProps {
    open: boolean;
    onClose: () => void;
    onSave: (countryData: any) => void;
    initialData?: {
        name: { [key: string]: string };
        iso_alpha_2: string;
        iso_alpha_3: string;
        em_reg_id: string;
        em_geocode_id: string;
    };
}

const CountryForm: React.FC<CountryFormProps> = ({ open, onClose, onSave, initialData }) => {
    console.log(initialData)
    const [countryData, setCountryData] = useState({
        name: { EN: '', FR: '' },
        iso_alpha_2: '',
        iso_alpha_3: '',
        em_reg_id: '',
        em_geocode_id: ''
    });

    useEffect(() => {
        if (initialData) {
            setCountryData({  
                ...initialData,
                name: {
                    EN: initialData.name.EN || '',  // Utilise 'en' ou une chaîne vide par défaut
                    FR: initialData.name.FR || ''   // Utilise 'FR' ou une chaîne vide par défaut
                }});
        }
    }, [initialData]);
    console.log(countryData)
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: string }>) => {
        const { name, value } = e.target;
        setCountryData({
            ...countryData,
            [name]: value
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{initialData ? "Update Country" : "Ajouter une localisation"}</DialogTitle>
            <DialogContent>
                <TextField
                    label="ISO Alpha 2"
                    name="iso_alpha_2"
                    value={countryData.iso_alpha_2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="ISO Alpha 3"
                    name="iso_alpha_3"
                    value={countryData.iso_alpha_3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Region ID"
                    name="em_reg_id"
                    value={countryData.em_reg_id}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Geocode ID"
                    name="em_geocode_id"
                    value={countryData.em_geocode_id}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Nom du pays (English)"
                    name="name_EN"
                    value={countryData.name['EN']}
                    onChange={(e) => setCountryData({
                        ...countryData,
                        name: { ...countryData.name, EN: e.target.value }
                    })}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="Nom du pays (French)"
                    name="name_FR"
                    value={countryData.name['FR']}
                    onChange={(e) => setCountryData({
                        ...countryData,
                        name: { ...countryData.name, FR: e.target.value }
                    })}
                    fullWidth
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Annuler</Button>
                <Button onClick={() => onSave(countryData)} color="primary">
                    {initialData ? "Update" : "Ajouter"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CountryForm;
