import React from 'react';
import {createAuthProvider} from 'react-token-auth';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './i18n';
import http from "./http-common";


ReactDOM.render(
  <Router> 
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"></link>
      <App />
  </Router>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


 
 
export const [useAuth, authFetch, login, logout] =
    createAuthProvider<{ accessToken: string, refreshToken: string }>({
        accessTokenKey: 'accessToken',
        onUpdateToken: (token) => fetch('http://localhost:1818/service/refresh', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token.accessToken}
        })
        .then(r => r.json())
    });
