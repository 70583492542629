import React, { ReactElement, useCallback } from "react"
import TabTitle from "./tab-title.component"
import './tabs-panel.scss'

type Props = {
  children: ReactElement[]
  value: number
  onChange: (event: any, newValue: number) => void // Accepte 'any' comme premier argument
}

const Tabs: React.FC<Props> = ({ children, value, onChange }) => {

  const handleTabChange = useCallback((index: number) => {
    onChange(null, index) // Vous pouvez passer 'null' ou un objet vide
  }, [onChange])

  return (
    <div>
      <ul>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={handleTabChange}
            isSelected={value === index}
          />
        ))}
      </ul>
      {children[value]}
    </div>
  )
}

export default Tabs
