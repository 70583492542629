import React from 'react';
import { Button } from '@material-ui/core';

interface SaveButtonProps {
  onClick: () => void;  // Fonction à exécuter lors du clic sur le bouton
  style?: React.CSSProperties;  // Styles optionnels
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, style }) => {
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: '#15afad',
        color: 'white',
        ...style
      }}
      onClick={onClick}
    >
      Enregistrer
    </Button>
  );
};

export default SaveButton;
