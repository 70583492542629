import React, { Component, createRef } from 'react';
import './Checkbox.scss';

export interface Props {
  disable: boolean;
  checkedValue?: boolean;
  indeterminate?: boolean;
  onChange?: (checked: boolean) => void;
}

interface State {
  isValueCheck?: boolean;
}

export default class Checkbox extends React.Component<Props, State> {
  private checkboxRef = createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isValueCheck: this.props.checkedValue || false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.checkedValue !== prevState.isValueCheck) {
      return { isValueCheck: nextProps.checkedValue };
    }
    return null;
  }

  componentDidMount() {
    if (this.checkboxRef.current) {
      this.checkboxRef.current.indeterminate = !!this.props.indeterminate;
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (this.checkboxRef.current) {
      this.checkboxRef.current.indeterminate = !!this.props.indeterminate;
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    this.setState({ isValueCheck: isChecked });
    if (this.props.onChange) {
      this.props.onChange(isChecked);
    }
  }

  render() {
    const { disable, indeterminate } = this.props;
    return (
      <div className="custom-checkbox">
        <input
          type="checkbox"
          className="input-checkbox"
          id="selection"
          ref={this.checkboxRef}
          checked={this.state.isValueCheck}
          disabled={disable}
          onChange={this.handleChange}
        />
        <span
          className={`custom_checkbox_control ${indeterminate ? 'indeterminate' : ''}`}
        >
          <i className={`material-icons ${indeterminate ? 'indeterminate' : ''}`}>{indeterminate ? 'indeterminate_check_box' : 'check'}</i>
        </span>
      </div>
    );
  }
}
