import React from 'react';
import './loading.scss';

const Loading = () => {
  return (
    <div className="loader">
    </div>
  );
}

export default Loading;
