import React, { useState, useEffect } from 'react';
import './Client-Options.scss';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ConsoleAdminService from '../../../services/console-admin.service';
import { Trans } from 'react-i18next';
import Loading from '../custom-components/loading/loading';
import SaveButton from '../custom-components/saveButton/SaveButton';
import NotificationSnackbar from '../custom-components/notificationSnackbar/NotificationSnackbar';
import LangList from '../custom-components/langList/LangList';
import CountryList from '../custom-components/countryList/CountryList';
import DeleteIcon from '@material-ui/icons/Delete';
interface ClientOptionsType {
    langList: { value: string; countries: string[] }[];
    countries: {
        name: { [key: string]: string };
        iso_alpha_2: string;
        iso_alpha_3: string;
        em_reg_id: string;
        em_geocode_id: string;
    }[];
    mapType: string;
    poiConfig?: {
        tooltips: {
            image: {
                messages: string[];
            };
        };
        maxPhotoAgency: number;
    };
    answerTemplates?: {
        name: string;
        answer: string;
    }[];
}

interface ClientOptionsProps {
    clientId: string;
}

const ClientOptions: React.FC<ClientOptionsProps> = ({
    clientId = '',
}) => {
    const [ClientOptions, setClientOptions] = useState<ClientOptionsType>({
        langList: [],
        countries: [],
        mapType: '',
        poiConfig: {
            tooltips: {
                image: {
                    messages: ['JPEG', 'PNG']
                }
            },
            maxPhotoAgency: 10
        }
    });
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');

    const init = async () => {
        try {
            const response = await ConsoleAdminService.getClientOptions(clientId);
            const data = response.data as ClientOptionsType;
            setClientOptions(data);
            setSelectedLanguage(data.langList[0].value);
            setLoading(false);
        } catch (error) {
            setSnackbarMessage('Échec de recupération des Client Options');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        init();
    }, [clientId]);

    const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newLang = event.target.value as string;
        setSelectedLanguage(newLang);
    };
    const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCountry(event.target.value as string);
    };

    const handleSave = async () => {
        try {
            const data = JSON.parse(JSON.stringify(ClientOptions));
            const response = await ConsoleAdminService.updateClientOptions(clientId, data);
            if (response.status !== 200) {
                throw new Error('Failed to update Client Options');
            }
            setSnackbarMessage('parameters.clientOptions.messageSnackbar.succesToUpdate');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('parameters.clientOptions.messageSnackbar.failedToUpdate');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteCountry = (iso_alpha_2: string) => {
        const newCountries = ClientOptions.countries.filter(country => country.iso_alpha_2 !== iso_alpha_2);
        setClientOptions({ ...ClientOptions, countries: newCountries });
    };
    const handleAddCountry = (newCountry: any) => {
        setClientOptions({
            ...ClientOptions,
            countries: [...ClientOptions.countries, newCountry]
        });
    };
    const handleUpdateCountry = (iso_alpha_2: string, updatedCountry: any) => {
        const updatedCountries = ClientOptions.countries.map(country =>
            country.iso_alpha_2 === iso_alpha_2 ? updatedCountry : country
        );
        setClientOptions({ ...ClientOptions, countries: updatedCountries });
    };

    const handleMaxPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxPhoto = parseInt(event.target.value, 10);
        setClientOptions((prev) => ({
            ...prev,
            poiConfig: {
                ...prev.poiConfig,
                maxPhotoAgency: isNaN(newMaxPhoto) ? 0 : newMaxPhoto,
                tooltips: {
                    image: {
                        messages: prev.poiConfig?.tooltips?.image?.messages || []
                    }
                }
            }
        }));
    };

    const handleImageFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedFormats = event.target.value as string[];

        setClientOptions((prev) => ({
            ...prev,
            poiConfig: {
                ...prev.poiConfig,
                tooltips: {
                    image: {
                        messages: selectedFormats
                    }
                },
                maxPhotoAgency: prev.poiConfig?.maxPhotoAgency || 10,
            }
        }));
    };
    const handleTemplateChange = (index: number, field: string, value: string) => {
        const updatedTemplates = ClientOptions.answerTemplates?.map((template, idx) => {
            if (idx === index) {
                return { ...template, [field]: value };
            }
            return template;
        });
        setClientOptions(prev => ({
            ...prev,
            answerTemplates: updatedTemplates
        }));
    };

    const handleAddTemplate = () => {
        const newTemplate = { name: 'newTemplate', answer: 'Not configured' };
        setClientOptions(prev => ({
            ...prev,
            answerTemplates: [...(prev.answerTemplates || []), newTemplate]
        }));
    };

    const handleDeleteTemplate = (index: number) => {
        const filteredTemplates = ClientOptions.answerTemplates?.filter((_, idx) => idx !== index);
        setClientOptions(prev => ({
            ...prev,
            answerTemplates: filteredTemplates
        }));
    };
    if (loading) {
        return <Loading />;
    }

    return (
        <div className="client-options-page">
            <FormGroup className="client-options-group">
                <div>
                    <LangList
                        languages={ClientOptions?.langList || []}
                        selectedLanguage={selectedLanguage}
                        handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setSelectedLanguage(event.target.value as string);
                        }}
                    />
                </div>
                <div>
                    <CountryList
                        countries={ClientOptions.countries}
                        selectedLanguage={selectedLanguage}
                        selectedCountry={selectedCountry}
                        handleChange={(e) => setSelectedCountry(e.target.value as string)}
                        handleDeleteCountry={handleDeleteCountry}
                        handleAddCountry={handleAddCountry}
                        handleUpdateCountry={handleUpdateCountry}
                    />
                </div>
                <div>
                    <FormControl>
                        <InputLabel id="image-format-label">Image Format</InputLabel>
                        <Select
                            labelId="image-format-label"
                            multiple
                            value={ClientOptions.poiConfig?.tooltips.image.messages || []}
                            onChange={handleImageFormatChange}
                            renderValue={(selected) => `Format : ${(selected as string[]).join('/')}`}
                        >
                            {['JPEG', 'PNG', 'GIF', 'SVG'].map((format) => (
                                <MenuItem key={format} value={format}>
                                    <Checkbox
                                        checked={ClientOptions.poiConfig?.tooltips.image.messages?.includes(format) || false}
                                    />
                                    {format}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Max Photo Agency"
                        type="number"
                        value={ClientOptions.poiConfig?.maxPhotoAgency || 0}
                        onChange={handleMaxPhotoChange}
                    />
                </div>
                <div className="template-list">
                    {ClientOptions.answerTemplates?.map((template, index) => (
                        <div key={index} className="template-item">
                            <TextField
                                label="Template Name"
                                value={template.name}
                                onChange={(e) => handleTemplateChange(index, 'name', e.target.value)}
                                style={{ flex: 1 }}
                            />
                            <TextField
                                label="Answer"
                                value={template.answer}
                                onChange={(e) => handleTemplateChange(index, 'answer', e.target.value)}
                                className="answer-input"
                                multiline
                                rows={4}
                            />
                            <IconButton onClick={() => handleDeleteTemplate(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button onClick={handleAddTemplate} className="add-template-button">Add New Template</Button>
                </div>
            </FormGroup>

            <SaveButton onClick={handleSave} />
            <NotificationSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default ClientOptions;
