import { DragEvent, useState } from 'react';
import './fileUploader.scss'

export function FileDrop(props: any) {
  const [isOver, setIsOver] = useState(false);
  const [file, setFile] = useState<File>();

  // Define the event handlers
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);

    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFile(droppedFiles[0]);
    props.readFile(droppedFiles[0]);
  };

  const onFileUpload = function(event: any) {
      event.preventDefault();
      if (event.target.files && event.target.files[0]) {
        setFile(event.target.files[0]);
        handleFile(event.target.files);
      }
  };
  
  function handleFile(files: any) {
    props.readFile(files[0]);
  }

  return (
    <div className="dragdrop"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        height: '215px',
        width: '580px',
        border: '2px dashed #E9E9E9',
        backgroundColor: isOver ? 'lightgray' : 'white',
      }}
    >
      Cliquez ici pour parcourir ou déposer un fichier ici
      <input type="file" onChange={onFileUpload} hidden={false}/>
      {
        file ? <p>File uploaded : {file.name}</p> : <p>Pas de fichier sélectionné.</p>
      }
      </div>
  );
}


