import React from 'react'

interface Props {
  title: string
  children: React.ReactNode
}

class Tab extends React.Component<Props> {
  render() {
    return <div>{this.props.children}</div>
  }
}

export default Tab
