import React, { ReactNode, useState } from 'react';
import './Add-Poi-Modal.scss';
import Button from '@material-ui/core/Button';
import deleteTagIcon from '../../../../../assets/img/element_item_supprime_white.png'

export interface Props {
  optionalBtn?: {btnTitle: string, btnStyle: string}[];
  poiList: string[];
  updatePoiList: (newList: string[]) => void;
  updateText: string;
  children?: ReactNode;
}
interface State{
  openModal: boolean;
  tagsList: string[];
  tagsInput: string;
  optionalBtn: {btnTitle: string, btnStyle: string}[];
}


class AddPoiModal extends React.Component<Props, State> {

  optionalBtn = [
    {
      btnTitle: "Tout supprimer",
      btnStyle: "customButton  margin5 red-btn w120",
      btnAction: ()=>{this.deleteAllTags()}
    }
  ]

  constructor(props: Props) {
    super(props);
    this.state = {
      openModal : true,
      tagsList: this.props.poiList || [],
      tagsInput: '',
      optionalBtn : this.optionalBtn
    }

    this.addTags = this.addTags.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
    this.deleteAllTags = this.deleteAllTags.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  IMAGES = [
    "ACME0001",
    "ACME0002",
    "ACME0003",
    "ACME0004",
    "ACME0005",
  ];

  getModalStyle(){
    const top = 100;
    const left = 100;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

    addTags() {
       this.setState({  
        tagsList :[...this.state.tagsList, ...this.state.tagsInput.split(',')],
        tagsInput : ''}, ()=>this.props.updatePoiList(this.state.tagsList));
    };

    deleteAllTags() {
      this.setState({  
        tagsList :[]}, ()=>this.props.updatePoiList(this.state.tagsList));
    }
    
    deleteTag(tag: string) {
      var poiList = this.state.tagsList;
      var index = poiList.indexOf(tag)
      poiList.splice(index, 1);
      this.setState({  
        tagsList : new Array(...poiList)}, ()=>this.props.updatePoiList(this.state.tagsList));
   };

    handleInputChange(event: any){
      this.setState({  
        tagsInput : event.target.value});
    };

  handleClose(){

  }
  render(){
    return(
        <div>
          <div className="panel" >
            <div>
              <div>
                  <label>
                    {this.props.updateText}
                      <input type="text" value={this.state.tagsInput} onChange={this.handleInputChange} name="addTag" />
                      <Button variant="contained" onClick={this.addTags} > Ajouter </Button>
                  </label>
                  <input type="text" name="filter" placeholder="Filtre" />
              </div>
              <div>
                <div className="container">
                  <div className="row mt-2 mb-2 text-center" >
                    {this.state.tagsList.map(image => (
                      <div className="tag">
                        <a className="text">
                          {image}
                        </a>
                        <img className="icon" src={deleteTagIcon} onClick={() => {this.deleteTag(image)}}></img>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                  <button className="customButton red-btn w120" onClick={this.deleteAllTags}> Tout Supprimer </button>
              </div>
            </div>

          </div>
          <div className="info">

          </div>
      </div>
    );
  }

}

export default AddPoiModal;
