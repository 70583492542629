import React, { useCallback } from "react"
import './tabs-panel.scss'

type Props = {
  title: string
  index: number
  setSelectedTab: (index: number) => void
  isSelected: boolean
}

const TabTitle: React.FC<Props> = ({ title, setSelectedTab, index,isSelected }) => {

  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index])

  return (
    <li>
      <button className={isSelected? 'selected' : ''} onClick={onClick}>{title}</button>
    </li>
  )
}

export default TabTitle