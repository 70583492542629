/**
 *
 * @export
 * @class Guid
 * @see @url{https://github.com/NicolasDeveloper/guid-typescript}
 */
export default class Guid {
  public static readonly VALIDATOR = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$', 'i');
  public static readonly EMPTY = '00000000-0000-0000-0000-000000000000';

  public static isGuid(guid: Guid | string): boolean {
    const value: string = guid.toString();
    return Guid.VALIDATOR.test(value);
  }

  public static create(): Guid {
    return new Guid([Guid._gen(2), Guid._gen(1), Guid._gen(1), Guid._gen(1), Guid._gen(3)].join('-'));
  }

  public static createEmpty(): Guid {
    return new Guid('emptyguid');
  }

  public static parse(guid: string): Guid {
    return new Guid(guid);
  }

  public static raw(): string {
    return [Guid._gen(2), Guid._gen(1), Guid._gen(1), Guid._gen(1), Guid._gen(3)].join('-');
  }

  private static _gen(count: number) {
    let out: string = '';
    for (let i: number = 0; i < count; i++) {
      // tslint:disable-next-line:no-bitwise
      out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return out;
  }

  private value: string;

  private constructor(guid: string) {
    if (!guid) { throw new TypeError('Invalid argument; `value` has no value.'); }

    this.value = Guid.EMPTY;

    if (guid && Guid.isGuid(guid)) {
      this.value = guid;
    }
  }

  public equals(other: Guid): boolean {
    // Comparing string `value` against provided `guid` will auto-call
    // toString on `guid` for comparison
    return Guid.isGuid(other) && this.value === other.toString();
  }

  public isEmpty(): boolean {
    return this.value === Guid.EMPTY;
  }

  public toString(): string {
    return this.value;
  }

  public toJSON(): { value: string } {
    return {
      value: this.value,
    };
  }

  public clone(): Guid {
    return Guid.parse(this.toString());
  }
}
