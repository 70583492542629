import React, { useState } from 'react';
import Tabs from '../components/custom-components/tabs panel/tabs.component';
import Tab from '../components/custom-components/tabs panel/tab.component';
import ModulesAccess from '../components/modules-access/Modules-Access';
import ClientOptions from '../components/client-options/Client-Options';

interface ParametersProps {
  clientId: string;
}

const Parameters: React.FC<ParametersProps> = ({
  clientId = ''
}) => {
  const [selectedTab, setCurrentTab] = useState<number | string>(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string | number) => {
    setCurrentTab(newValue);
  };
  return (
    <div className="parameters-page">
      <div className="tab-content">
      <Tabs value={Number(selectedTab)} onChange={handleTabChange}>
            <Tab title="Acces Module">
              <ModulesAccess
                clientId={clientId} />
            </Tab>
            <Tab title="Options Client">
            <ClientOptions
                clientId={clientId} />
            </Tab>
          </Tabs>
      </div>
    </div>
  );
};

export default Parameters;
