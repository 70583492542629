import history from "../history";
import userAdminService from "./user-admin.service";

class RoutingService {
    navigateTo(uri:string) {
        history.push(uri);
    }

    navigateToProtected(uri:string) {
        userAdminService.protected().then(response => {
            if(response.status == 200) {
                this.navigateTo(uri);
            }
        })
    }

}
  
  export default new RoutingService();